import React from 'react'
import { CardModal, Type } from '@/components/common'
import { Campaign } from '@/services/api/campaign'
import StretchGoalModalListItem from './StretchGoalsModalListItem'

export interface Props {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  campaign: Campaign
}

const StretchGoalsModal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  campaign,
}) => {
  const { stretchGoal, stretchGoals, name } = campaign

  // If no stretch goal, then there are no incomplete goals,
  // so set current goal index to stretch goals length so all goals
  // appear in the completed list
  const currentGoalIdx = stretchGoal
    ? stretchGoals.findIndex((g) => g.amount === stretchGoal.amount)
    : stretchGoals.length

  const upNext = stretchGoals.slice(currentGoalIdx)
  const completed = stretchGoals.slice(0, currentGoalIdx)

  return (
    <CardModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="bg-core-gray-100 max-w-full py-6 px-5 sm:px-6 sm:pb-6 md:w-[500px]">
        <CardModal.Title className="pr-12 mb-4">
          <Type as="span" variant="title-sm" className="font-semibold">
            {name} Goals
          </Type>
        </CardModal.Title>

        {upNext.length > 0 && (
          <div className="mt-6">
            <Type
              as="h3"
              variant="title-xs"
              className="font-bold text-core-gray-700 mb-2"
            >
              Up Next
            </Type>
            <ul className="rounded-lg border border-gray-8 overflow-hidden">
              {upNext.map(({ title, amount, description }, idx) => (
                <StretchGoalModalListItem
                  key={amount}
                  idx={currentGoalIdx + idx + 1}
                  title={title}
                  amount={amount}
                  description={description}
                  status={idx === 0 ? 'in-progress' : 'default'}
                />
              ))}
            </ul>
          </div>
        )}

        {completed.length > 0 && (
          <div className="mt-6">
            <Type
              as="h3"
              variant="title-xs"
              className="font-bold text-core-gray-700 mb-2"
            >
              Completed Goals
            </Type>
            <ul className="rounded-lg border border-gray-8 overflow-hidden">
              {completed.map(({ title, amount, description }, idx) => (
                <StretchGoalModalListItem
                  key={amount}
                  idx={idx + 1}
                  title={title}
                  amount={amount}
                  description={description}
                  status="complete"
                />
              ))}
            </ul>
          </div>
        )}
      </div>
    </CardModal>
  )
}

export default StretchGoalsModal
