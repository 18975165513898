import React from 'react'
import { Text } from '@/components/common'
import { Checkmark } from '@/components/svg'
import { formatMoney } from '@/utils'

export interface Props {
  idx: number
  amount: number
  title: string
  description?: string
  status: 'default' | 'in-progress' | 'complete'
}

const StretchGoalsModalListItem: React.FC<Props> = ({
  idx,
  amount,
  title,
  description,
  status,
}) => {
  const formattedAmount = formatMoney(amount, {
    zeroFractionDigits: true,
  })

  return (
    <li
      key={amount}
      className="bg-white p-4 border-gray-8 border-b last:border-b-0"
    >
      <div className="flex items-start">
        <Text as="div" preset="heading.sm" className="mb-2">
          Goal {idx}: {formattedAmount}
        </Text>

        {status === 'in-progress' && (
          <div className="bg-copper-3 py-1 sm:py-[5px] px-2 rounded-sm ml-auto">
            <Text as="div" preset="heading.eyebrow" className="leading-none">
              In Progress
            </Text>
          </div>
        )}

        {status === 'complete' && (
          <div className="flex items-center ml-auto mt-[3px]">
            <Text as="div" preset="heading.eyebrow" className="leading-none">
              Completed
            </Text>
            <div className="bg-oxide w-[13px] h-[13px] p-[2px] rounded-full ml-1">
              <Checkmark className="w-[9px] h-[9px] text-white" />
            </div>
          </div>
        )}
      </div>
      <Text as="div" preset="body.md" className="font-semibold text-gray-5">
        {title}
      </Text>
      {description && (
        <Text as="div" preset="body.md" className="text-gray-5 mt-1">
          {description}
        </Text>
      )}
    </li>
  )
}

export default StretchGoalsModalListItem
