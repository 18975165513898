import React from 'react'
import classNames from 'classnames'
import { progressUtil } from '@/utils'
import StretchGoal from './StretchGoal'
import { Campaign } from '@/services/api/campaign'
import useSiteAssets from '@/services/hooks/useSiteAssets'

export interface Props {
  campaign: Campaign
  theme?: 'dark' | 'light'
  className?: string
}

const ProgressBar: React.FC<Props> = ({
  campaign,
  theme = 'dark',
  className,
}) => {
  const { progress, capitalRaised, priorRaiseTotal } = campaign
  const siteAssets = useSiteAssets()

  let resolvedProgress = progress
  if (siteAssets?.usePriorRaisesLayout) {
    const priorRaisePlusCurrent = priorRaiseTotal.amount + capitalRaised
    resolvedProgress =
      (priorRaisePlusCurrent / siteAssets.priorRaisesGoalAmount) * 100
  }

  return (
    <div className={className}>
      <StretchGoal campaign={campaign} theme={theme} />

      <div
        className={classNames({
          'w-full h-2 relative rounded-lg': true,
          'bg-white': theme === 'light',
          'bg-core-gray-200': theme === 'dark',
        })}
      >
        <div
          role="progressbar"
          className={classNames('w-full  h-2 relative rounded-lg', {
            // anything smaller than min-width looks bad with rounded corners
            'min-w-[0.5rem]': resolvedProgress > 0,
            'bg-[#C0A175]': siteAssets.useAltProgressColor,
            'bg-core-oxide-core': !siteAssets.useAltProgressColor,
          })}
          style={{
            width: progressUtil.getProgressWidth(resolvedProgress),
          }}
        />
      </div>
    </div>
  )
}

export default ProgressBar
